const BackToHome = () => {
  return (
    <div className="back-to-home-content">
      <a href="https://payqin.com/#/" className="back-to-home-link">
        <div className="back-to-home-button">Retour à la page d'accueil</div>
      </a>
    </div>
  );
};

export default BackToHome;
