import { useEffect, useState } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Suspense } from "react";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import Retailers from "./components/Retailers";
import BackToHome from "./utils/BackToHome";

ReactGA.initialize("UA-140533197-1");

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <Suspense fallback="Loading ...">
      <HashRouter>
        <div className="App">  
          <Switch>
            <Route path="/retailers-map">
              <Retailers />
            </Route>
          </Switch>
        </div>
        <BackToHome />
      </HashRouter>
    </Suspense>
  );
}

export default App;
