import { useEffect, useState } from "react";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { getRetailersLocations } from "../api/getRetailersMap";

const WrappedMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 5.317613, lng: -4.006326 }}
    >
      {props.retailers &&
        props.retailers.map((retailer) => (
          <Marker
            key={retailer._id}
            position={{
              lat: retailer.gpsCoords && retailer.gpsCoords.lat,
              lng: retailer.gpsCoords && retailer.gpsCoords.lng,
            }}
            onClick={() => {
              props.setSelectedRetailer(retailer);
            }}
            icon={{
              url: "https://res.cloudinary.com/payqin-ltd/image/upload/v1662651880/main%20website/Products/Q-2-small_rhzfdh.png",
            }}
          />
        ))}
      {props.selectedRetailer && (
        <InfoWindow
          position={{
            lat:
              props.selectedRetailer.gpsCoords &&
              props.selectedRetailer.gpsCoords.lat,
            lng:
              props.selectedRetailer.gpsCoords &&
              props.selectedRetailer.gpsCoords.lng,
          }}
          onCloseClick={() => {
            props.setSelectedRetailer(null);
            console.log("bonjour le monde");
          }}
        >
          <div>
            {props.selectedRetailer.accountType === "company" ? (
              <h3 style={{ marginBottom: "2%", textTransform: "uppercase" }}>
                {props.selectedRetailer.name && props.selectedRetailer.name}
              </h3>
            ) : (
              <h3 style={{ marginBottom: "2%", textTransform: "uppercase" }}>
                {props.selectedRetailer.firstName &&
                  props.selectedRetailer.firstName +
                    " " +
                    props.selectedRetailer.lastName}
              </h3>
            )}
            <p style={{ marginBottom: "3%" }}>
              <i className="fas fa-envelope"></i>{" "}
              {props.selectedRetailer.email && props.selectedRetailer.email}
            </p>
            <p style={{ marginBottom: "3%" }}>
              <i className="fas fa-phone"></i>{" "}
              {props.selectedRetailer.phone && props.selectedRetailer.phone}
            </p>
            <p>
              <i className="fas fa-map"></i>{" "}
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${
                  props.selectedRetailer.gpsCoords &&
                  props.selectedRetailer.gpsCoords.lat
                },${
                  props.selectedRetailer.gpsCoords &&
                  props.selectedRetailer.gpsCoords.lng
                }`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                {" "}
                Voir sur google maps{" "}
              </a>
            </p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  ))
);

const Retailers = () => {
  const [retailers, setRetailers] = useState([]);
  const [selectedRetailer, setSelectedRetailer] = useState(null);

  const getRetailers = async () => {
    console.log("Effect activé sur le chargement des distributeurs");
    const resp = await getRetailersLocations();
    if (resp.success) {
      console.log(resp.retailers);
      setRetailers(resp.retailers);
    }
  };

  useEffect(() => {
    getRetailers();
  }, []);

  return (
    <div className="retailer_wrapper_right">
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyAryWcRLPEri5xBQ7o7fDjK5UkNdc67GgM&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `700px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        retailers={retailers}
        selectedRetailer={selectedRetailer}
        setSelectedRetailer={setSelectedRetailer}
      />      
    </div>
  );
};

export default Retailers;
